<template>
  <div class="tw-bg-[#F6C46A] tw-shadow tw-rounded tw-p-4 tw-flex tw-gap-4">
    <i class="airalo-icon-warning tw-text-[40px]" />
    <span class="tw-text-[14px]/[20px] tw-font-semibold tw-flex tw-items-center tw-whitespace-pre-line">{{
      message
    }}</span>
  </div>
</template>

<script>
  export default {
    props: {
      message: {
        type: String,
        required: true,
      },
    },
  }
</script>
