<template>
  <div class="c--sim_additional-information additional-information-wrapper" :class="{ 'wrapper-modal': isModal }">
    <div
      class="additional-information-header"
      data-testid="additional-information-screen"
      :class="{
        'mb-20': !isModal,
        'header-modal': isModal,
      }"
      v-if="title && title.length > 0"
    >
      <p class="title-sub">{{ title }}</p>
    </div>
    <div class="additional-information-container" :class="{ 'body-modal': isModal }">
      <box-simple :shadowed="isShadowedBox" :rounded="isRoundedBox" class="w-100">
        <ul class="additional-information" :class="{ 'list-modal': isModal }">
          <li v-if="networkList.length > 0" @click.prevent="networkItemClick(type)" class="item-network-modal">
            <div class="additional-information-item">
              <div class="info-icon">
                <span class="airalo-icon-network tw-text-neutral-600" />
              </div>
              <div class="info-content" data-testid="network-info">
                <p class="info-title">
                  {{ $t('global.network') }}
                </p>
                <template v-if="type === 'global'">
                  <p class="info-text">
                    <span>{{ $t('global.multiple-network') }}</span>
                  </p>
                </template>
                <template v-else>
                  <p class="info-network-list">
                    <span v-for="(item, index) in networkList" :key="`additional-information-network-item-${index}`">
                      <span dir="auto">{{ item.network }}</span>
                      <span
                        :class="[
                          `airalo-icon-${item.service_type.toLowerCase()}`,
                          'airalo-icon-wrapper tw-text-neutral-600 tw-text-4',
                        ]"
                      />
                    </span>
                  </p>
                </template>
              </div>
              <div class="info-right-icon">
                <span class="right-icon d-flex">
                  <span
                    id="networks-info"
                    data-testid="networks-info-button"
                    :class="[
                      {
                        'airalo-icon-arrow-right tw-text-3 tw-text-neutral-600': type === 'global',
                        'airalo-icon-exclamation-mark-round tw-text-neutral-600': type !== 'global',
                        'rtl-transform-rotate-y': $dir() === 'rtl',
                      },
                    ]"
                  />
                </span>
                <template v-if="type !== 'global'">
                  <Tooltip
                    target="networks-info"
                    :text="$t('components.network.tooltip.content')"
                    :button-text="$t('components.tooltip.got-it-button')"
                    :is-clone-target="true"
                  />
                </template>
              </div>
            </div>
          </li>
          <li>
            <div class="additional-information-item">
              <div class="info-icon">
                <span class="airalo-icon-memo tw-text-neutral-600" />
              </div>
              <div class="info-content">
                <p class="info-title">
                  {{ $t('global.plan-type') }}
                </p>
                <p class="info-text" data-testid="plan-type-info-text">
                  {{
                    planType === 'data'
                      ? $t('global.plan-type-data')
                      : planType === 'data-voice'
                      ? $t('global.plan-type-data-voice')
                      : planType === 'data-voice-text'
                      ? $t('global.plan-type-data-calls-texts')
                      : planType === 'data-incoming-call'
                      ? $t('global.plan-type-data-incoming-call')
                      : $t('global.plan-type-data')
                  }}
                </p>
              </div>
              <div class="info-right-icon">
                <span class="right-icon d-flex" id="plan-type-info">
                  <span class="airalo-icon-exclamation-mark-round tw-text-neutral-600 pointered" />
                </span>
                <Tooltip
                  target="plan-type-info"
                  :text="
                    planType === 'data'
                      ? $t('components.plan.type.data.tooltip.content')
                      : planType === 'data-voice'
                      ? $t('components.plan.type.data.voice.tooltip.content')
                      : planType === 'data-voice-text'
                      ? $t('components.plan.type.data.voice.text.tooltip.content')
                      : planType === 'data-incoming-call'
                      ? $t('components.plan.type.data.incoming.call.tooltip.content')
                      : $t('components.plan.type.data.tooltip.content')
                  "
                  :button-text="$t('components.tooltip.got-it-button')"
                  :is-clone-target="true"
                />
              </div>
            </div>
          </li>
          <li>
            <div class="additional-information-item">
              <div class="info-icon">
                <span class="airalo-icon-policy tw-text-neutral-600" />
              </div>
              <div class="info-content" data-testid="activation-policy">
                <p class="info-title">
                  {{ $t('global.activation-policy') }}
                </p>
                <p class="info-text">
                  {{
                    activationPolicy === 'installation'
                      ? $t('components.activation.policy.install')
                      : activationPolicy === 'first-usage'
                      ? $t('components.activation.policy.first-usage')
                      : $t('components.activation.policy.install')
                  }}
                </p>
              </div>
            </div>
          </li>
          <li v-if="callingCredit && callingCredit.length > 0">
            <div class="additional-information-item">
              <div class="info-icon">
                <span class="airalo-icon-phone-arrow-up-right tw-text-neutral-600" />
              </div>
              <div class="info-content" data-testid="calling-credit">
                <p class="info-title">
                  {{ $t('global.calling-credit') }}
                </p>
                <p class="info-text">
                  {{ callingCredit }}
                </p>
              </div>
            </div>
          </li>
          <li v-if="hasRoutingSection">
            <div class="additional-information-item">
              <div class="tw-mr-1">
                <nuxt-img
                  src="/assets/images/svg/ic_routing.svg"
                  alt="routing"
                  height="20px"
                  width="20px"
                  class="w-auto"
                  loading="lazy"
                />
              </div>
              <div class="info-content" data-testid="routing">
                <p class="info-title">
                  {{ $t('components.additional.info-ip-routing') }}
                </p>
                <p class="info-text">
                  {{
                    isRouting
                      ? $t('components.additional.info-ip-routing-yes')
                      : $t('components.additional.info-ip-routing-no')
                  }}
                </p>
              </div>
              <div class="info-right-icon">
                <span class="right-icon d-flex">
                  <span
                    id="routing-info"
                    data-testid="routing-info-button"
                    class="airalo-icon-exclamation-mark-round tw-text-neutral-600"
                  />
                </span>
                <template>
                  <Tooltip
                    target="routing-info"
                    :text="$t('components.additional.info-ip-routing-info')"
                    :button-text="$t('components.tooltip.got-it-button')"
                    :is-clone-target="true"
                  />
                </template>
              </div>
            </div>
          </li>
          <li>
            <div class="additional-information-item">
              <div class="info-icon">
                <span class="airalo-icon-fingerprint-1 tw-text-neutral-600" />
              </div>
              <div class="info-content" data-testid="ekyc-info">
                <p class="info-title">
                  {{ $t('global.ekyc') }}
                </p>
                <p class="info-text">
                  {{ eKyc ? $t('global.ekyc-yes') : $t('global.ekyc-no') }}
                  <span v-if="eKyc"
                    >-
                    {{
                      isKycOneTime
                        ? $t('components.additional.info-ekyc-one-time')
                        : $t('components.additional.info-ekyc-default')
                    }}</span
                  >
                </p>
              </div>
              <div class="info-right-icon">
                <span class="right-icon d-flex" id="ekyc-identity-verification" v-if="eKyc">
                  <span class="airalo-icon-exclamation-mark-round tw-text-neutral-600 pointered" />
                </span>
                <template v-if="eKyc">
                  <Tooltip
                    target="ekyc-identity-verification"
                    :text="
                      isKycOneTime
                        ? $t('components.additional.info-ekyc-tooltip-one-time')
                        : $t('components.additional.info-ekyc-tooltip-default')
                    "
                    :button-text="$t('components.tooltip.got-it-button')"
                    :is-clone-target="true"
                  />
                </template>
              </div>
            </div>
          </li>
          <li v-if="eKycValidation != null">
            <div class="additional-information-item">
              <div class="info-icon">
                <span class="airalo-icon-fingerprint-validation tw-text-neutral-600" />
              </div>
              <div class="info-content">
                <p class="info-title">
                  {{ $t('components.additional.info-ekyc-validation') }}
                </p>
                <p class="info-text">
                  {{
                    eKycValidation > 1
                      ? $t('components.additional.info-ekyc-validation-hours', {
                          eKycDuration: eKycValidation,
                        })
                      : $t('components.additional.info-ekyc-validation-hour', {
                          eKycDuration: eKycValidation,
                        })
                  }}
                </p>
              </div>
            </div>
          </li>
          <li v-if="eKycRestriction != null">
            <div class="additional-information-item">
              <div class="info-icon">
                <span class="airalo-icon-fingerprint-restriction tw-text-neutral-600" />
              </div>
              <div class="info-content">
                <p class="info-title">
                  {{ $t('components.additional.info-ekyc-restriction') }}
                </p>
                <p class="info-text">
                  {{ eKycRestriction }}
                </p>
              </div>
            </div>
          </li>
          <li>
            <div class="additional-information-item">
              <div class="info-icon">
                <span class="airalo-icon-history-plus tw-text-neutral-600" />
              </div>
              <div class="info-content" data-testid="top-up-options">
                <p class="info-title">
                  {{ $t('global.rechargability-option') }}
                </p>
                <p class="info-text">
                  {{ topupOption ? $t('global.rechargability-option-yes') : $t('global.rechargability-option-no') }}
                </p>
              </div>
            </div>
          </li>
          <li v-if="otherInfo.length > 0">
            <div class="additional-information-item">
              <div class="info-icon">
                <span class="airalo-icon-exclamation-mark-round tw-text-neutral-600" />
              </div>
              <div class="info-content">
                <p class="info-title">
                  {{ $t('global.other-info') }}
                </p>
                <p class="info-text">
                  {{ otherInfo }}
                </p>
              </div>
            </div>
          </li>
        </ul>
      </box-simple>
    </div>
  </div>
</template>

<script>
  export default {
    components: {
      BoxSimple: () => import('~/components/card-boxes/box-type-simple.vue'),
      Tooltip: () => import('~/components/shared/overlay-tooltip.vue'),
    },
    props: {
      type: {
        type: String,
        required: false,
        default: 'local', // local, regional, global
      },
      title: {
        type: String,
        required: true,
      },
      networkList: {
        type: Array,
        required: false,
        default: () => [],
      },
      planType: {
        type: String,
        required: true,
      },
      activationPolicy: {
        type: String,
        required: true,
      },
      callingCredit: {
        type: String,
        required: false,
        default: '',
      },
      eKyc: {
        type: Boolean,
        required: true,
      },
      eKycValidation: {
        type: [String, Number],
        required: false,
      },
      eKycRestriction: {
        required: false,
        default: null,
      },
      isKycOneTime: {
        type: Boolean,
        required: true,
      },
      topupOption: {
        type: Boolean,
        required: true,
      },
      hasRoutingSection: {
        type: Boolean,
        required: true,
      },
      isRouting: {
        type: Boolean,
        required: true,
      },
      otherInfo: {
        type: String,
        required: false,
        default: '',
      },
      isShadowedBox: {
        type: Boolean,
        required: false,
        default: false,
      },
      isRoundedBox: {
        type: Boolean,
        required: false,
        default: false,
      },
      isModal: {
        type: Boolean,
        required: false,
        default: false,
      },
    },

    methods: {
      networkItemClick(type) {
        if (type === 'global') {
          this.openNetworksModal()
        }
      },
      openNetworksModal() {
        this.$emit('onOpenNetworksModal')
      },
    },
  }
</script>

<style lang="scss" scoped>
  .additional-information {
    overflow: auto;

    &.list-modal {
      overflow: unset;
    }

    &-wrapper {
      &.wrapper-modal {
        display: flex;
        flex-direction: column;
        height: 100%;
      }
    }

    li {
      padding: 13px 16px 11px 20px;
      border-bottom: 1px solid #eeeeee;

      &.item-network-modal {
        cursor: pointer;
      }
    }

    &-item {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      p {
        margin: 0;
      }

      .info {
        &-icon {
          margin-right: 10px;

          [dir='rtl'] & {
            margin-right: unset;
            margin-left: 10px;
          }
        }

        &-right-icon {
          margin-left: auto;

          [dir='rtl'] & {
            margin-left: unset;
            margin-right: auto;
          }
        }

        &-title {
          color: #8a8a8a;
          font-size: 0.6875rem;
          font-weight: 600;
          letter-spacing: 1px;
          line-height: 1.2727;
          margin-bottom: 3px;
        }

        &-text,
        &-button {
          color: #4a4a4a;
          font-size: 0.9375rem;
          font-weight: 500;
          letter-spacing: 0;
          line-height: 1.3333;
        }

        &-button {
          margin: 0;
          padding: 0;
          text-decoration: none;
        }

        &-network-list {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: center;

          .airalo-icon-wrapper {
            margin-left: 5px;

            [dir='rtl'] & {
              margin-left: unset;
              margin-right: 5px;
            }
          }

          span {
            color: #4a4a4a;
            font-size: 0.9375rem;
            font-weight: 500;
            letter-spacing: 0;
            line-height: 1.3333;
            display: inline-flex;
          }
        }
      }
    }

    &-header {
      &.header-modal {
        padding: 0 20px;
        padding-bottom: 20px;
        border-bottom: 1px solid #eeeeee;
      }

      p {
        margin: 0;
      }
    }

    &-container {
      display: flex;
      align-items: stretch;
      max-height: 315px;
      min-height: 315px;

      &.body-modal {
        max-height: unset;
        min-height: unset;

        overflow: hidden;
        overflow-y: auto;

        @media (max-width: 575px) {
          max-height: unset;
          min-height: unset;
        }
      }

      @media (max-width: 575px) {
        min-height: 270px;
        max-height: 270px;
      }
    }
  }
</style>
