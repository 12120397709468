<template>
  <b-modal :id="modalId" size="supported-countries" v-model="modalView" centered hide-header hide-footer @hide="onHide">
    <div class="c--modals_supported-countries-modal modal-supported-countries-close" :dir="$dir()">
      <a-icon
        type="image"
        loader="data"
        class="modal-supported-countries-close-btn"
        name="icon-search-clear"
        width="30"
        @click.native="$bvModal.hide(modalId)"
      />
    </div>
    <div class="supported-countries-item-wrapper pt-40">
      <country-list
        type="modal"
        :is-networks-modal="isNetworksModal"
        :is-apns-modal="isApnsModal"
        :is-clickable="isClickable"
        :title="title"
        :description="description"
        :empty-text="emptyText"
        :search-input-placeholder="searchInputPlaceholder"
        :list="countries"
        :searchable="true"
        :is-shadowed-box="false"
        :is-rounded-box="false"
        @onClickItem="onClickItem"
        class="h-100"
      />
    </div>
  </b-modal>
</template>

<script>
  import CountryList from '~/components/shared/country-list'

  export default {
    name: 'supported-countries-modal',
    components: {
      CountryList,
    },
    props: {
      modalId: {
        type: String,
        required: false,
        default: 'supported-countries-modal',
      },
      isNetworksModal: {
        type: Boolean,
        required: false,
        default: false,
      },
      isApnsModal: {
        type: Boolean,
        required: false,
        default: false,
      },
      isClickable: {
        type: Boolean,
        required: false,
        default: false,
      },
      description: {
        type: String,
        required: false,
        default: '',
      },
      modalView: {
        type: Boolean,
        required: true,
      },
      countries: {
        type: Array,
        required: true,
      },
      title: {
        type: String,
        required: false,
        default: '',
      },
      searchInputPlaceholder: {
        type: String,
        required: false,
        default: '',
      },
      emptyText: {
        type: String,
        required: false,
        default: '',
      },
    },

    methods: {
      onHide(evt) {
        if (evt.trigger === 'backdrop' || evt.trigger === 'esc' || evt.trigger === 'event') {
          evt.preventDefault()
          this.$emit('onCloseSupportedCountriesModal')
        }
      },

      onClickItem(item) {
        this.$emit('onClickItem', item)
      },
    },
  }
</script>

<style lang="scss" scoped>
  .supported-countries-item-wrapper {
    height: 100%;
  }

  ::v-deep {
    .modal-supported-countries {
      max-width: 540px;

      &-close {
        &-btn {
          cursor: pointer;
          padding: 0;
          margin: 0;
          display: flex;
          position: absolute;
          right: 5px;
          top: 5px;
          z-index: 10;

          [dir='rtl'] & {
            right: unset;
            left: 5px;
          }
        }
      }

      .modal-body {
        padding: 0;
        height: 540px;
        overflow: hidden;
      }

      @media (max-width: 575px) {
        width: 100vw;
        height: 100vh;
        max-width: unset;
        min-height: unset;
        margin: 0;
      }

      .modal-content {
        box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.15);
        border-radius: 7px;

        @media (max-width: 575px) {
          height: 100%;
          border-radius: 0;
        }
      }
    }
  }
</style>
