var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": _vm.modalId,
      "size": "supported-countries",
      "centered": "",
      "hide-header": "",
      "hide-footer": ""
    },
    on: {
      "hide": _vm.onHide
    },
    model: {
      value: _vm.modalView,
      callback: function ($$v) {
        _vm.modalView = $$v;
      },
      expression: "modalView"
    }
  }, [_c('div', {
    staticClass: "c--modals_supported-countries-modal modal-supported-countries-close",
    attrs: {
      "dir": _vm.$dir()
    }
  }, [_c('a-icon', {
    staticClass: "modal-supported-countries-close-btn",
    attrs: {
      "type": "image",
      "loader": "data",
      "name": "icon-search-clear",
      "width": "30"
    },
    nativeOn: {
      "click": function ($event) {
        return _vm.$bvModal.hide(_vm.modalId);
      }
    }
  })], 1), _vm._v(" "), _c('div', {
    staticClass: "supported-countries-item-wrapper pt-40"
  }, [_c('country-list', {
    staticClass: "h-100",
    attrs: {
      "type": "modal",
      "is-networks-modal": _vm.isNetworksModal,
      "is-apns-modal": _vm.isApnsModal,
      "is-clickable": _vm.isClickable,
      "title": _vm.title,
      "description": _vm.description,
      "empty-text": _vm.emptyText,
      "search-input-placeholder": _vm.searchInputPlaceholder,
      "list": _vm.countries,
      "searchable": true,
      "is-shadowed-box": false,
      "is-rounded-box": false
    },
    on: {
      "onClickItem": _vm.onClickItem
    }
  })], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }