var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "tw-bg-[#F6C46A] tw-shadow tw-rounded tw-p-4 tw-flex tw-gap-4"
  }, [_c('i', {
    staticClass: "airalo-icon-warning tw-text-[40px]"
  }), _vm._v(" "), _c('span', {
    staticClass: "tw-text-[14px]/[20px] tw-font-semibold tw-flex tw-items-center tw-whitespace-pre-line"
  }, [_vm._v(_vm._s(_vm.message))])]);

}
var staticRenderFns = []

export { render, staticRenderFns }