<template>
  <div
    :class="[
      'c--shared_country-list',
      `country-list-wrapper type-${type}${isSearchable ? ' searchable' : ''}`,
      {
        'networks-modal': isNetworksModal || isApnsModal,
      },
    ]"
  >
    <div
      class="country-list-header"
      :class="{ 'mb-20': type === 'inline', 'px-20': type === 'modal' }"
      v-if="type !== 'modal' && title && title.length > 0"
    >
      <p class="title-sub">{{ title }}</p>
    </div>
    <div class="country-list-container" data-testid="country-list">
      <box-simple :shadowed="isShadowedBox" :rounded="isRoundedBox" class="w-100">
        <div class="country-list-header px-20" v-if="type === 'modal' && title && title.length > 0">
          <p class="title-sub">{{ title }}</p>
          <p class="title-sub-description" v-if="description.length > 0">
            {{ description }}
          </p>
        </div>
        <div class="country-list-search-container" v-if="isSearchable">
          <span class="icon search">
            <i class="airalo-icon-search tw-text-neutral-600" />
          </span>
          <input
            v-model="searchValue"
            :placeholder="searchInputPlaceholder"
            class="country-list-search w-100"
            data-testid="country-list-search-input"
            type="text"
          />
          <span class="icon close-icon" v-if="searchValue.length > 0" @click.prevent="clearSearch">
            <i class="airalo-icon-close-round-filled tw-text-neutral-600" />
          </span>
        </div>
        <ul class="country-list" :class="{ 'empty-list': !searchedCountryList.length }">
          <li
            v-for="country in searchedCountryList"
            :key="`popup-country-list-${country.slug}`"
            :data-testid="`${country.slug}-name`"
            :class="[
              isNetworksModal || isApnsModal ? `d-flex justify-content-between align-items-center` : '',
              {
                'cursor-pointer': isClickable || (!isClickable && isApnsModal),
              },
            ]"
            :ref="`popup-country-list-${country.slug}`"
            @click.stop.capture="onClickItem(country, `popup-country-list-${country.slug}`, $event)"
          >
            <country-name-in-flag
              :country-flag-url="
                country.image ? country.image.url : '/assets/images/eSIM-Card-Visual-Placeholder@2x.png'
              "
              :country-flag-width="37"
              :country-name="country.title"
              :class="type === 'inline' ? 'flex-row-reverse justify-content-between' : ''"
            />
            <template v-if="isNetworksModal">
              <div class="d-flex flex-column align-items-end justify-content-center country-list-item-networks">
                <span v-for="(item, index) in country.networks" :key="`networks-list-item-${index}`">
                  {{ item.network }}
                  <img
                    :src="require(`~/assets/images/svg/icon-package-${item.service_type.toLowerCase()}-light.svg?data`)"
                    alt=""
                    width="20"
                    height="20"
                    data-not-lazy
                  />
                </span>
              </div>
            </template>
            <template v-if="isApnsModal">
              <div class="d-flex flex-column align-items-end justify-content-center country-list-item-apns">
                <span>
                  {{ country.apn }}
                  <template v-if="!isClickable && isApnsModal">
                    <a-icon
                      type="image"
                      loader="data"
                      name="icon-esim-detail-copy"
                      :theme="'light'"
                      :class="[
                        'cursor-pointer',
                        {
                          'ml-20': $dir() === 'ltr',
                          'mr-20': $dir() === 'rtl',
                        },
                      ]"
                      width="22"
                      disabled="disabled"
                    />
                  </template>
                </span>
              </div>
            </template>
          </li>
          <li v-if="!searchedCountryList.length" class="empty">
            <p>
              {{ emptyText.length > 0 ? emptyText : $t('global.no-country-available') }}
            </p>
          </li>
        </ul>
      </box-simple>
    </div>
  </div>
</template>

<script>
  import { removeAccents } from '~/plugins/remove-accents'

  import BoxSimple from '~/components/card-boxes/box-type-simple.vue'
  import CountryNameInFlag from '~/components/shared/country-name-in-flag.vue'

  export default {
    components: {
      BoxSimple,
      CountryNameInFlag,
    },
    props: {
      type: {
        type: String,
        required: false,
        default: 'inline', // inline, modal
      },
      isNetworksModal: {
        type: Boolean,
        required: false,
        default: false,
      },
      isApnsModal: {
        type: Boolean,
        required: false,
        default: false,
      },
      isClickable: {
        type: Boolean,
        required: false,
        default: false,
      },
      list: {
        type: Array,
        required: true,
      },
      searchable: {
        type: Boolean,
        required: false,
        default: false,
      },
      title: {
        type: String,
        required: true,
      },
      description: {
        type: String,
        required: false,
        default: '',
      },
      emptyText: {
        type: String,
        required: false,
        default: '',
      },
      searchInputPlaceholder: {
        type: String,
        required: false,
        default: '',
      },
      isShadowedBox: {
        type: Boolean,
        required: false,
        default: false,
      },
      isRoundedBox: {
        type: Boolean,
        required: false,
        default: false,
      },
    },
    data() {
      return {
        searchValue: '',
      }
    },
    computed: {
      isSearchable() {
        if (!this.searchable && this.list.length > 4) {
          return true
        } else if (this.searchable) {
          return true
        } else {
          return false
        }
      },
      searchedCountryList() {
        if (this.searchValue) {
          return this.list.filter((country) => {
            return removeAccents(country.title.toLowerCase()).includes(removeAccents(this.searchValue.toLowerCase()))
          })
        } else {
          return this.list
        }
      },
    },
    methods: {
      clearSearch() {
        this.searchValue = ''
      },

      copyClipboardAPNCode(text, country) {
        this.$bvToast.toast(
          this.$t(`scenes.apn.list.apn-copied`, {
            apn: text,
            country,
          }),
          {
            title: this.$t('messages.success.title'),
            variant: 'success',
            solid: true,
            hidden: (v) => {},
          }
        )
      },

      onClickItem(item, name, e) {
        if (!this.isClickable && this.isApnsModal) {
          this.$copyText(item.apn, this.$refs[name][0].querySelector('span.airalo-icon-wrapper')).then(
            (e) => {
              this.copyClipboardAPNCode(item.apn, item.title)
            },
            (e) => {
              console.error({ error: e })
            }
          )
        }
        this.$emit('onClickItem', item)
      },
    },
  }
</script>

<style lang="scss" scoped>
  .country-list {
    overflow: auto;

    &-item-networks {
      span {
        display: inline-flex;
        justify-content: flex-end;
        align-items: center;
        color: #4a4a4a;
        font-size: 0.9375rem;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 1.3333;
        white-space: nowrap;

        img {
          margin-left: 5px;

          [dir='rtl'] & {
            margin-left: unset;
            margin-right: 5px;
          }
        }
      }
    }

    &-item-apns {
      span {
        display: inline-flex;
        justify-content: flex-end;
        align-items: center;
        color: #4a4a4a;
        font-size: 1.0625rem;
        font-weight: 500;
        letter-spacing: -0.1px;
        line-height: 1.17647;
        white-space: nowrap;
      }
    }

    li {
      padding: 10px 20px;
      border-bottom: 1px solid #eeeeee;
    }

    &-container {
      display: flex;
      align-items: stretch;
    }

    &-search {
      border: none;
      border-radius: 0;
      background-color: transparent;
      padding: 0;
      min-height: 22px;

      color: #4a4a4a;
      font-size: 0.9375rem;
      letter-spacing: 0;
      line-height: 1;
      font-weight: 400;

      &::placeholder,
      &:-ms-input-placeholder,
      &::-ms-input-placeholder {
        color: #8a8a8a;
      }

      &-container {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;

        border: 1px solid #dddddd;
        border-radius: 7px;
        background-color: #fafafa;
        padding: 13px 0;

        margin: 20px;

        & + .country-list {
          border-top: 1px solid #eeeeee;
        }

        .icon {
          position: relative;
          display: flex;

          &.search {
            margin-left: 20px;
            margin-right: 10px;

            [dir='rtl'] & {
              margin-left: 10px;
              margin-right: 20px;
            }
          }

          &.close-icon {
            margin-right: 20px;
            margin-left: 10px;
            cursor: pointer;

            [dir='rtl'] & {
              margin-left: 20px;
              margin-right: 10px;
            }
          }
        }
      }
    }

    &-wrapper {
      &.type-inline {
        &.searchable {
          .country-list {
            &-container {
              min-height: 315px;

              @media (max-width: 575px) {
                min-height: 270px;
              }
            }
          }
        }

        .country-list {
          &-container {
            max-height: 315px;
            min-height: 315px;

            @media (max-width: 575px) {
              max-height: 270px;
              min-height: unset;
            }
          }

          &.empty-list {
            display: flex;
            justify-content: center;
            align-items: center;
            flex: 1;
          }

          li {
            &.empty {
              border-bottom: none;

              p {
                color: #8a8a8a;
                margin: 0;
                font-size: 0.9375rem;
                font-weight: 400;
                line-height: 1.4;
                letter-spacing: 0;
              }
            }

            ::v-deep {
              .country-name {
                padding-right: 0;

                [dir='rtl'] & {
                  padding-right: unset;
                  padding-left: 0;
                }

                p {
                  margin-left: 0;
                  font-size: 0.8125rem;
                  font-weight: 400;
                  line-height: 1.35;

                  [dir='rtl'] & {
                    margin-left: unset;
                    margin-right: 0;
                  }
                }
              }
            }
          }
        }
      }

      &.type-modal {
        .country-list {
          padding: 0 0 0 20px;

          [dir='rtl'] & {
            padding: 0 20px 0 0;
          }

          &-container {
            height: 100%;
          }

          &.empty-list {
            display: flex;
            justify-content: center;
            align-items: center;
            flex: 1;
          }

          li {
            padding: 22.5px 20px 22.5px 0;

            [dir='rtl'] & {
              padding: 22.5px 0 22.5px 20px;
            }

            &.empty {
              border-bottom: none;

              p {
                color: #8a8a8a;
                margin: 0;
                font-size: 0.9375rem;
                font-weight: 400;
                line-height: 1.4;
                letter-spacing: 0;
              }
            }
          }
        }

        &.networks-modal {
          .country-list {
            li {
              padding: 17px 20px 16px 0;

              [dir='rtl'] & {
                padding: 17px 0 16px 20px;
              }
            }
          }
        }
      }
    }
  }
</style>
