var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "c--sim_additional-information additional-information-wrapper",
    class: {
      'wrapper-modal': _vm.isModal
    }
  }, [_vm.title && _vm.title.length > 0 ? _c('div', {
    staticClass: "additional-information-header",
    class: {
      'mb-20': !_vm.isModal,
      'header-modal': _vm.isModal
    },
    attrs: {
      "data-testid": "additional-information-screen"
    }
  }, [_c('p', {
    staticClass: "title-sub"
  }, [_vm._v(_vm._s(_vm.title))])]) : _vm._e(), _vm._v(" "), _c('div', {
    staticClass: "additional-information-container",
    class: {
      'body-modal': _vm.isModal
    }
  }, [_c('box-simple', {
    staticClass: "w-100",
    attrs: {
      "shadowed": _vm.isShadowedBox,
      "rounded": _vm.isRoundedBox
    }
  }, [_c('ul', {
    staticClass: "additional-information",
    class: {
      'list-modal': _vm.isModal
    }
  }, [_vm.networkList.length > 0 ? _c('li', {
    staticClass: "item-network-modal",
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.networkItemClick(_vm.type);
      }
    }
  }, [_c('div', {
    staticClass: "additional-information-item"
  }, [_c('div', {
    staticClass: "info-icon"
  }, [_c('span', {
    staticClass: "airalo-icon-network tw-text-neutral-600"
  })]), _vm._v(" "), _c('div', {
    staticClass: "info-content",
    attrs: {
      "data-testid": "network-info"
    }
  }, [_c('p', {
    staticClass: "info-title"
  }, [_vm._v("\n                " + _vm._s(_vm.$t('global.network')) + "\n              ")]), _vm._v(" "), _vm.type === 'global' ? [_c('p', {
    staticClass: "info-text"
  }, [_c('span', [_vm._v(_vm._s(_vm.$t('global.multiple-network')))])])] : [_c('p', {
    staticClass: "info-network-list"
  }, _vm._l(_vm.networkList, function (item, index) {
    return _c('span', {
      key: `additional-information-network-item-${index}`
    }, [_c('span', {
      attrs: {
        "dir": "auto"
      }
    }, [_vm._v(_vm._s(item.network))]), _vm._v(" "), _c('span', {
      class: [`airalo-icon-${item.service_type.toLowerCase()}`, 'airalo-icon-wrapper tw-text-neutral-600 tw-text-4']
    })]);
  }), 0)]], 2), _vm._v(" "), _c('div', {
    staticClass: "info-right-icon"
  }, [_c('span', {
    staticClass: "right-icon d-flex"
  }, [_c('span', {
    class: [{
      'airalo-icon-arrow-right tw-text-3 tw-text-neutral-600': _vm.type === 'global',
      'airalo-icon-exclamation-mark-round tw-text-neutral-600': _vm.type !== 'global',
      'rtl-transform-rotate-y': _vm.$dir() === 'rtl'
    }],
    attrs: {
      "id": "networks-info",
      "data-testid": "networks-info-button"
    }
  })]), _vm._v(" "), _vm.type !== 'global' ? [_c('Tooltip', {
    attrs: {
      "target": "networks-info",
      "text": _vm.$t('components.network.tooltip.content'),
      "button-text": _vm.$t('components.tooltip.got-it-button'),
      "is-clone-target": true
    }
  })] : _vm._e()], 2)])]) : _vm._e(), _vm._v(" "), _c('li', [_c('div', {
    staticClass: "additional-information-item"
  }, [_c('div', {
    staticClass: "info-icon"
  }, [_c('span', {
    staticClass: "airalo-icon-memo tw-text-neutral-600"
  })]), _vm._v(" "), _c('div', {
    staticClass: "info-content"
  }, [_c('p', {
    staticClass: "info-title"
  }, [_vm._v("\n                " + _vm._s(_vm.$t('global.plan-type')) + "\n              ")]), _vm._v(" "), _c('p', {
    staticClass: "info-text",
    attrs: {
      "data-testid": "plan-type-info-text"
    }
  }, [_vm._v("\n                " + _vm._s(_vm.planType === 'data' ? _vm.$t('global.plan-type-data') : _vm.planType === 'data-voice' ? _vm.$t('global.plan-type-data-voice') : _vm.planType === 'data-voice-text' ? _vm.$t('global.plan-type-data-calls-texts') : _vm.planType === 'data-incoming-call' ? _vm.$t('global.plan-type-data-incoming-call') : _vm.$t('global.plan-type-data')) + "\n              ")])]), _vm._v(" "), _c('div', {
    staticClass: "info-right-icon"
  }, [_c('span', {
    staticClass: "right-icon d-flex",
    attrs: {
      "id": "plan-type-info"
    }
  }, [_c('span', {
    staticClass: "airalo-icon-exclamation-mark-round tw-text-neutral-600 pointered"
  })]), _vm._v(" "), _c('Tooltip', {
    attrs: {
      "target": "plan-type-info",
      "text": _vm.planType === 'data' ? _vm.$t('components.plan.type.data.tooltip.content') : _vm.planType === 'data-voice' ? _vm.$t('components.plan.type.data.voice.tooltip.content') : _vm.planType === 'data-voice-text' ? _vm.$t('components.plan.type.data.voice.text.tooltip.content') : _vm.planType === 'data-incoming-call' ? _vm.$t('components.plan.type.data.incoming.call.tooltip.content') : _vm.$t('components.plan.type.data.tooltip.content'),
      "button-text": _vm.$t('components.tooltip.got-it-button'),
      "is-clone-target": true
    }
  })], 1)])]), _vm._v(" "), _c('li', [_c('div', {
    staticClass: "additional-information-item"
  }, [_c('div', {
    staticClass: "info-icon"
  }, [_c('span', {
    staticClass: "airalo-icon-policy tw-text-neutral-600"
  })]), _vm._v(" "), _c('div', {
    staticClass: "info-content",
    attrs: {
      "data-testid": "activation-policy"
    }
  }, [_c('p', {
    staticClass: "info-title"
  }, [_vm._v("\n                " + _vm._s(_vm.$t('global.activation-policy')) + "\n              ")]), _vm._v(" "), _c('p', {
    staticClass: "info-text"
  }, [_vm._v("\n                " + _vm._s(_vm.activationPolicy === 'installation' ? _vm.$t('components.activation.policy.install') : _vm.activationPolicy === 'first-usage' ? _vm.$t('components.activation.policy.first-usage') : _vm.$t('components.activation.policy.install')) + "\n              ")])])])]), _vm._v(" "), _vm.callingCredit && _vm.callingCredit.length > 0 ? _c('li', [_c('div', {
    staticClass: "additional-information-item"
  }, [_c('div', {
    staticClass: "info-icon"
  }, [_c('span', {
    staticClass: "airalo-icon-phone-arrow-up-right tw-text-neutral-600"
  })]), _vm._v(" "), _c('div', {
    staticClass: "info-content",
    attrs: {
      "data-testid": "calling-credit"
    }
  }, [_c('p', {
    staticClass: "info-title"
  }, [_vm._v("\n                " + _vm._s(_vm.$t('global.calling-credit')) + "\n              ")]), _vm._v(" "), _c('p', {
    staticClass: "info-text"
  }, [_vm._v("\n                " + _vm._s(_vm.callingCredit) + "\n              ")])])])]) : _vm._e(), _vm._v(" "), _vm.hasRoutingSection ? _c('li', [_c('div', {
    staticClass: "additional-information-item"
  }, [_c('div', {
    staticClass: "tw-mr-1"
  }, [_c('nuxt-img', {
    staticClass: "w-auto",
    attrs: {
      "src": "/assets/images/svg/ic_routing.svg",
      "alt": "routing",
      "height": "20px",
      "width": "20px",
      "loading": "lazy"
    }
  })], 1), _vm._v(" "), _c('div', {
    staticClass: "info-content",
    attrs: {
      "data-testid": "routing"
    }
  }, [_c('p', {
    staticClass: "info-title"
  }, [_vm._v("\n                " + _vm._s(_vm.$t('components.additional.info-ip-routing')) + "\n              ")]), _vm._v(" "), _c('p', {
    staticClass: "info-text"
  }, [_vm._v("\n                " + _vm._s(_vm.isRouting ? _vm.$t('components.additional.info-ip-routing-yes') : _vm.$t('components.additional.info-ip-routing-no')) + "\n              ")])]), _vm._v(" "), _c('div', {
    staticClass: "info-right-icon"
  }, [_c('span', {
    staticClass: "right-icon d-flex"
  }, [_c('span', {
    staticClass: "airalo-icon-exclamation-mark-round tw-text-neutral-600",
    attrs: {
      "id": "routing-info",
      "data-testid": "routing-info-button"
    }
  })]), _vm._v(" "), [_c('Tooltip', {
    attrs: {
      "target": "routing-info",
      "text": _vm.$t('components.additional.info-ip-routing-info'),
      "button-text": _vm.$t('components.tooltip.got-it-button'),
      "is-clone-target": true
    }
  })]], 2)])]) : _vm._e(), _vm._v(" "), _c('li', [_c('div', {
    staticClass: "additional-information-item"
  }, [_c('div', {
    staticClass: "info-icon"
  }, [_c('span', {
    staticClass: "airalo-icon-fingerprint-1 tw-text-neutral-600"
  })]), _vm._v(" "), _c('div', {
    staticClass: "info-content",
    attrs: {
      "data-testid": "ekyc-info"
    }
  }, [_c('p', {
    staticClass: "info-title"
  }, [_vm._v("\n                " + _vm._s(_vm.$t('global.ekyc')) + "\n              ")]), _vm._v(" "), _c('p', {
    staticClass: "info-text"
  }, [_vm._v("\n                " + _vm._s(_vm.eKyc ? _vm.$t('global.ekyc-yes') : _vm.$t('global.ekyc-no')) + "\n                "), _vm.eKyc ? _c('span', [_vm._v("-\n                  " + _vm._s(_vm.isKycOneTime ? _vm.$t('components.additional.info-ekyc-one-time') : _vm.$t('components.additional.info-ekyc-default')))]) : _vm._e()])]), _vm._v(" "), _c('div', {
    staticClass: "info-right-icon"
  }, [_vm.eKyc ? _c('span', {
    staticClass: "right-icon d-flex",
    attrs: {
      "id": "ekyc-identity-verification"
    }
  }, [_c('span', {
    staticClass: "airalo-icon-exclamation-mark-round tw-text-neutral-600 pointered"
  })]) : _vm._e(), _vm._v(" "), _vm.eKyc ? [_c('Tooltip', {
    attrs: {
      "target": "ekyc-identity-verification",
      "text": _vm.isKycOneTime ? _vm.$t('components.additional.info-ekyc-tooltip-one-time') : _vm.$t('components.additional.info-ekyc-tooltip-default'),
      "button-text": _vm.$t('components.tooltip.got-it-button'),
      "is-clone-target": true
    }
  })] : _vm._e()], 2)])]), _vm._v(" "), _vm.eKycValidation != null ? _c('li', [_c('div', {
    staticClass: "additional-information-item"
  }, [_c('div', {
    staticClass: "info-icon"
  }, [_c('span', {
    staticClass: "airalo-icon-fingerprint-validation tw-text-neutral-600"
  })]), _vm._v(" "), _c('div', {
    staticClass: "info-content"
  }, [_c('p', {
    staticClass: "info-title"
  }, [_vm._v("\n                " + _vm._s(_vm.$t('components.additional.info-ekyc-validation')) + "\n              ")]), _vm._v(" "), _c('p', {
    staticClass: "info-text"
  }, [_vm._v("\n                " + _vm._s(_vm.eKycValidation > 1 ? _vm.$t('components.additional.info-ekyc-validation-hours', {
    eKycDuration: _vm.eKycValidation
  }) : _vm.$t('components.additional.info-ekyc-validation-hour', {
    eKycDuration: _vm.eKycValidation
  })) + "\n              ")])])])]) : _vm._e(), _vm._v(" "), _vm.eKycRestriction != null ? _c('li', [_c('div', {
    staticClass: "additional-information-item"
  }, [_c('div', {
    staticClass: "info-icon"
  }, [_c('span', {
    staticClass: "airalo-icon-fingerprint-restriction tw-text-neutral-600"
  })]), _vm._v(" "), _c('div', {
    staticClass: "info-content"
  }, [_c('p', {
    staticClass: "info-title"
  }, [_vm._v("\n                " + _vm._s(_vm.$t('components.additional.info-ekyc-restriction')) + "\n              ")]), _vm._v(" "), _c('p', {
    staticClass: "info-text"
  }, [_vm._v("\n                " + _vm._s(_vm.eKycRestriction) + "\n              ")])])])]) : _vm._e(), _vm._v(" "), _c('li', [_c('div', {
    staticClass: "additional-information-item"
  }, [_c('div', {
    staticClass: "info-icon"
  }, [_c('span', {
    staticClass: "airalo-icon-history-plus tw-text-neutral-600"
  })]), _vm._v(" "), _c('div', {
    staticClass: "info-content",
    attrs: {
      "data-testid": "top-up-options"
    }
  }, [_c('p', {
    staticClass: "info-title"
  }, [_vm._v("\n                " + _vm._s(_vm.$t('global.rechargability-option')) + "\n              ")]), _vm._v(" "), _c('p', {
    staticClass: "info-text"
  }, [_vm._v("\n                " + _vm._s(_vm.topupOption ? _vm.$t('global.rechargability-option-yes') : _vm.$t('global.rechargability-option-no')) + "\n              ")])])])]), _vm._v(" "), _vm.otherInfo.length > 0 ? _c('li', [_c('div', {
    staticClass: "additional-information-item"
  }, [_c('div', {
    staticClass: "info-icon"
  }, [_c('span', {
    staticClass: "airalo-icon-exclamation-mark-round tw-text-neutral-600"
  })]), _vm._v(" "), _c('div', {
    staticClass: "info-content"
  }, [_c('p', {
    staticClass: "info-title"
  }, [_vm._v("\n                " + _vm._s(_vm.$t('global.other-info')) + "\n              ")]), _vm._v(" "), _c('p', {
    staticClass: "info-text"
  }, [_vm._v("\n                " + _vm._s(_vm.otherInfo) + "\n              ")])])])]) : _vm._e()])])], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }