var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    class: ['c--shared_country-list', `country-list-wrapper type-${_vm.type}${_vm.isSearchable ? ' searchable' : ''}`, {
      'networks-modal': _vm.isNetworksModal || _vm.isApnsModal
    }]
  }, [_vm.type !== 'modal' && _vm.title && _vm.title.length > 0 ? _c('div', {
    staticClass: "country-list-header",
    class: {
      'mb-20': _vm.type === 'inline',
      'px-20': _vm.type === 'modal'
    }
  }, [_c('p', {
    staticClass: "title-sub"
  }, [_vm._v(_vm._s(_vm.title))])]) : _vm._e(), _vm._v(" "), _c('div', {
    staticClass: "country-list-container",
    attrs: {
      "data-testid": "country-list"
    }
  }, [_c('box-simple', {
    staticClass: "w-100",
    attrs: {
      "shadowed": _vm.isShadowedBox,
      "rounded": _vm.isRoundedBox
    }
  }, [_vm.type === 'modal' && _vm.title && _vm.title.length > 0 ? _c('div', {
    staticClass: "country-list-header px-20"
  }, [_c('p', {
    staticClass: "title-sub"
  }, [_vm._v(_vm._s(_vm.title))]), _vm._v(" "), _vm.description.length > 0 ? _c('p', {
    staticClass: "title-sub-description"
  }, [_vm._v("\n          " + _vm._s(_vm.description) + "\n        ")]) : _vm._e()]) : _vm._e(), _vm._v(" "), _vm.isSearchable ? _c('div', {
    staticClass: "country-list-search-container"
  }, [_c('span', {
    staticClass: "icon search"
  }, [_c('i', {
    staticClass: "airalo-icon-search tw-text-neutral-600"
  })]), _vm._v(" "), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.searchValue,
      expression: "searchValue"
    }],
    staticClass: "country-list-search w-100",
    attrs: {
      "placeholder": _vm.searchInputPlaceholder,
      "data-testid": "country-list-search-input",
      "type": "text"
    },
    domProps: {
      "value": _vm.searchValue
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.searchValue = $event.target.value;
      }
    }
  }), _vm._v(" "), _vm.searchValue.length > 0 ? _c('span', {
    staticClass: "icon close-icon",
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.clearSearch.apply(null, arguments);
      }
    }
  }, [_c('i', {
    staticClass: "airalo-icon-close-round-filled tw-text-neutral-600"
  })]) : _vm._e()]) : _vm._e(), _vm._v(" "), _c('ul', {
    staticClass: "country-list",
    class: {
      'empty-list': !_vm.searchedCountryList.length
    }
  }, [_vm._l(_vm.searchedCountryList, function (country) {
    return _c('li', {
      key: `popup-country-list-${country.slug}`,
      ref: `popup-country-list-${country.slug}`,
      refInFor: true,
      class: [_vm.isNetworksModal || _vm.isApnsModal ? `d-flex justify-content-between align-items-center` : '', {
        'cursor-pointer': _vm.isClickable || !_vm.isClickable && _vm.isApnsModal
      }],
      attrs: {
        "data-testid": `${country.slug}-name`
      },
      on: {
        "!click": function ($event) {
          $event.stopPropagation();
          return _vm.onClickItem(country, `popup-country-list-${country.slug}`, $event);
        }
      }
    }, [_c('country-name-in-flag', {
      class: _vm.type === 'inline' ? 'flex-row-reverse justify-content-between' : '',
      attrs: {
        "country-flag-url": country.image ? country.image.url : '/assets/images/eSIM-Card-Visual-Placeholder@2x.png',
        "country-flag-width": 37,
        "country-name": country.title
      }
    }), _vm._v(" "), _vm.isNetworksModal ? [_c('div', {
      staticClass: "d-flex flex-column align-items-end justify-content-center country-list-item-networks"
    }, _vm._l(country.networks, function (item, index) {
      return _c('span', {
        key: `networks-list-item-${index}`
      }, [_vm._v("\n                " + _vm._s(item.network) + "\n                "), _c('img', {
        attrs: {
          "src": require(`~/assets/images/svg/icon-package-${item.service_type.toLowerCase()}-light.svg?data`),
          "alt": "",
          "width": "20",
          "height": "20",
          "data-not-lazy": ""
        }
      })]);
    }), 0)] : _vm._e(), _vm._v(" "), _vm.isApnsModal ? [_c('div', {
      staticClass: "d-flex flex-column align-items-end justify-content-center country-list-item-apns"
    }, [_c('span', [_vm._v("\n                " + _vm._s(country.apn) + "\n                "), !_vm.isClickable && _vm.isApnsModal ? [_c('a-icon', {
      class: ['cursor-pointer', {
        'ml-20': _vm.$dir() === 'ltr',
        'mr-20': _vm.$dir() === 'rtl'
      }],
      attrs: {
        "type": "image",
        "loader": "data",
        "name": "icon-esim-detail-copy",
        "theme": 'light',
        "width": "22",
        "disabled": "disabled"
      }
    })] : _vm._e()], 2)])] : _vm._e()], 2);
  }), _vm._v(" "), !_vm.searchedCountryList.length ? _c('li', {
    staticClass: "empty"
  }, [_c('p', [_vm._v("\n            " + _vm._s(_vm.emptyText.length > 0 ? _vm.emptyText : _vm.$t('global.no-country-available')) + "\n          ")])]) : _vm._e()], 2)])], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }